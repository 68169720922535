body, html, #root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  outline: none;
  border: none;
  background: none;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src:url('/public/fonts/RobotoMono-VariableFont_wght.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Roboto Thin', sans-serif;
  src:url('/public/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica', sans-serif;
  src:url('/public/fonts/Helvetica.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Helvetica', sans-serif;
  src:url('/public/fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: 700;
}
